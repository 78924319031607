.hero-circle-l {
  top: 70%;
  left: -10%;
  z-index: -250;
}

.hero-circle-r {
  top: -120px;
  z-index: -250;
}

.form-block {
  z-index: 999;
  border-top: 15px solid #7248B9;
  background-color: #FFFFFF;
}

.form-block-doc {
  z-index: 999;
  border: 1px solid #2655C9;
  background-color: #F3F6FB;
}
.fs-7{
  font-size: 14px!important;
}
.hero-list-l {
  top: 70%;
  left: 10%;
}

.hero-icon-middle {
  top: 220%;
  right: 10%;
}

.hero-list-r {
  right: 25%;
  bottom: -20%;
}

.hero-icon-bottom {
  right: -3%;
}

.hero-ic2 {
  left: 150px;
}

.hero-ic5 {
  left: 100px;
  top: 400px;
}

.hero-ic6 {
  top: 650px;
  left: -140px;
  z-index: 0;
}

.hero-ic3_m {
  left: 75%;
}

.hero-ic3 {
  top: 0;
  left: 430px;
}

.hero-ic1 {
  top: 40px;
  right: 400px;
}

.hero-ic7 {
  top: -120px;
}

.card {
  z-index: 1;
  height: 100%;
}

.curve {
  background-image: url("/public/static/pages/main-page-img/bg-curve1.svg");
  padding-top: 200px;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  z-index: -1;
}

.curve-bottom {
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;
}

.header-item {
  color: #3e4957;
}

.header-item:hover {
  background: #eddcf0;
  color: #9b27af;
}

.header-item:hover .header-outline {
  display: inline-block;
}

.header-outline {
  display: none;
  height: 4px;
}

.header-item:hover .header-img {
  -webkit-filter: invert(20%) sepia(100%) saturate(2536%) hue-rotate(280deg) brightness(77%) contrast(66%);
  filter: invert(20%) sepia(100%) saturate(2536%) hue-rotate(280deg) brightness(77%) contrast(66%);
}

.bg-f5 {
  background-color: #f5f5f5;
  z-index: -1;
}

div.benefits-img {
  top: -115px;
  left: -85px;
}

.popular-block {
  background: rgba(152, 152, 152, 0.3);
}

.save-block {
  width: 98%;
  background: #a19afa;
}

div.year-block {
  background: #796fef8c;
  border-radius: 12px 12px 0 0;
}

.line-white.line-white-left {
  left: 20px;
}

.line-white.line-white-right {
  right: 20px;
}

.color-text-blue {
  color: #3c057e;
}

.bg-blue {
  background: #796fef8c;
}

.color-text-blue-1 {
  border-radius: 12px 0 0 0;
}

.line-blue {
  z-index: 1;
  position: relative;
  border-top: 1px solid #695dee;
  width: 100%;
}

.line-gray {
  z-index: 1;
  /* position: absolute; */
  border-top: 1px solid #6228a8;
  width: 90%;
}

.line-white {
  z-index: 1;
  /* position: absolute; */
  border-top: 1px solid #e9e8ed;
  width: 100%;
}

.title-mobile-price {
  font-weight: 900;
  line-height: 123.19%;
  color: #3c057e;
}

.block-price {
  background-color: #3c057e;
}

.block-price .col-2 {
  border-right: 1px solid #e9e8ed;
}

.block-price .col-2:last-child {
  border: none;
}

.no-border .col-2 {
  border: none;
}

.text-mobile-price {
  line-height: 180%;
  color: #3c057e;
}

.btn.btn-primary.btn-lg.border-color-blue {
  border-color: $primary;
}

//div.carousel-indicators {
//  bottom: -65px;
//}
//
//.carousel-indicators [data-bs-target].indicator-dot {
//  background-color: $primary;
//  border-radius: 50%;
//  width: 12px;
//  height: 12px;
//  border: 0;
//}
//
//.carousel-indicators [data-bs-target].indicator-dot.active {
//  width: 35px;
//  height: 12px;
//  border: 0;
//  background: #6a007c;
//  border-radius: 100px;
//}

.mobile-price-line-white {
  z-index: 1;
  border-top: 1px solid #e9e8ed;
}

.mobile-price-line-blue {
  z-index: 1;
  border-top: 1px solid #695dee;
}

.mobile-check {
  width: 14px;
}

.block-extend-file-storage {
  color: #9c7dc1;
  font-size: 15px;
}

div.block-per-month {
  padding: 30px 0 7px 0;
  color: #3c057e;
}

div.popular-text {
  padding: 3px;
  top: -10px;
  font-size: 10px;
  color: #3c057e;
  background: rgba(60, 5, 126, 0.1);
  margin-left: 10px;
}

.border-r-d {
  border-radius: 0 0 12px 0;
}

.border-l-d {
  border-radius: 0 0 0 12px;
}

div.save-text {
  padding: 3px;
  top: -10px;
  font-size: 10px;
  background: #a19afa;
  margin-left: 10px;
}

.code-scanner-footer-scanner {
  min-width: 351px;
  min-height: 375px;
  border-radius: 12px;
}

@media screen and (max-width: 768px) {
  .code-scanner,
  .news-block-background,
  .svg-img-footer path {
    background-color: #f5f5f5;
    fill: #f5f5f5;
    stroke: none;
  }
}

.news-block-inner {
  border-radius: 5px;
  //box-shadow: 0 4px 10px -2px grey;
  background: #ffffff;
  width: 80%;
  left: 10%;
  padding: 0 20px;
  margin-top: -90px;
  min-height: 250px;
  height: auto;
  margin-bottom: 20px;
}

.label-block {
  background: rgba(155, 39, 175, 0.15);
  border-radius: 6px;
}

.refer {
  background: linear-gradient(
                  253.63deg,
                  #ecd7f0 2.07%,
                  #fffcff 39.95%,
                  #e4e2fc 96.93%
  );
}

.dots-refer-l {
  left: 220px;
  top: 270px;
}

.refer-desk {
  letter-spacing: 0.5em;
}

//.footer-block-btn-store {
//  max-width: 330px;
//  width: 100%;
//  border-radius: 4px;
//  line-height: 19px;
//  color: #ffffff;
//  border: none !important;
//}
//
//.btn-appstore {
//  background: #00bcd4;
//}
//
//.btn-googlestore {
//  background: #4caf50;
//}
//
//.btn-appstore:hover,
//.btn-googlestore:hover {
//  transition: all 0.5s;
//  transform: scale(1.2);
//  cursor: pointer;
//}

.carousel-control-next,
.carousel-control-prev {
  background: none;
  border: none;
  width: 50px;
  margin-top: -25px;
}

button.carousel-control-next {
  right: -5%;
  width: 60px;
}

button.carousel-control-prev {
  left: -5%;
  width: 60px;
}

.svg-img-f {
  height: 180px;
  background-color: #f5f5f5;
}

.svg-img-footer {
  height: 100%;
  width: 100%;
}

.svg-img-footer path {
  stroke: none;
  fill: #ffffff;
}

@media (max-width: 991px) {
  .block-per-month {
    padding: 30px 0 10px 0 !important;
  }

  .title-simple-block {
    text-align: center !important;
  }

  .responsive-boy1 {
    left: 40% !important;
    bottom: 110px !important;
    width: 100px !important;
  }

  //.svg-img {
  //  height: 100px !important;
  //  /* margin-top: 246px !important; */
  //}

  .responsive-girl1 {
    width: 150px !important;
    bottom: 130px !important;
    top: 25px !important;
    left: 60px !important;
  }

  .responsive-girl2 {
    top: 40px !important;
    left: 65% !important;
    bottom: 190px !important;
    width: 100px !important;
  }

  .responsive-circle1 {
    width: 100px !important;
  }

  .responsive-circle2 {
    width: 100px !important;
    top: 646px !important;
  }

  .responsive-qr1 {
    width: 70px !important;
    right: 10px !important;
  }

  .responsive-qr2 {
    width: 70px !important;
    top: -420px !important;
  }

  .responsive-icon-hit-left {
    left: 80px !important;

    width: 30px !important;
  }

  .responsive-icon-hit-right {
    width: 30px !important;
    right: 60px !important;
  }
}

@media (max-width: 767px) {
  .responsive-qr2 {
    top: -470px !important;
  }

  .responsive-circle2 {
    width: 70px !important;
    top: 689px !important;
  }
}

.art-code-title {
  color: #020202;

}

.art-code-text {
  color: #020202;
  font-size: 18px;
  @media screen and (max-width: 431px) {
    font-size: 14px;
  }
}

.art-code-bg {
  background-image: url("/public/static/pages/main-page-img/art-code-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  @media screen and (max-width: 861px) and (min-width: 767px) {
    height: 700px;
    display: flex;
    align-items: center;
    background-image: url("/public/static/pages/main-page-img/art-code-bg_t.svg");
  }
  @media screen and (max-width: 767px) {
    background-image: url("/public/static/pages/main-page-img/art-code-bg_m.svg");
    height: auto;
  }
}

@media (max-width: 992px) {
  .currency {
    width: 86px;
    margin: 0 auto;
  }
}

.btn-white {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #ffffff !important;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-border-radius: 0.5rem;
  --bs-btn-hover-color: #3c057e !important;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: #ffffff;
  --bs-btn-active-color: #3c057e;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
}

.cultural {
  background: radial-gradient(
                  248.15% 348.88% at -45.08% -43.92%,
                  #eb301d 0%,
                  #0e0d17 34.38%,
                  #0e0d17 52.6%,
                  #eb5647 80.21%
  ) #ffffff;
}

.cultural-col {
  background: linear-gradient(180deg, #eb5647 0%, #ff8f84 100%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  .text {
    font-size: 14px;
  }
}

.cultural-line {
  background: linear-gradient(180deg, #eb5647 0%, #ff8f84 100%);
  width: 41px;
  height: 2px;
}

.me-ticket-bg {
  background-image: url("/public/static/pages/main-page-img/me-ticket-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (max-width: 900px) and (min-width: 600px) {
    background-image: url("/public/static/pages/main-page-img/me-ticket-bg-t.png");
  }
  @media screen and (max-width: 768px) {
    background-image: url("/public/static/pages/main-page-img/me-ticket-bg-m.svg");
  }
}
.ticket-img{
  @media screen and (max-width: 1022px) and (min-width: 600px) {
    overflow: hidden;
  }
}

.btn-ticket {
  &:hover {
    color: white !important;
    background-color: #16b054!important;
  }
}

.img-cover {
  height: 254px !important;
  object-fit: cover;
  object-position: center center;
  @media (max-width: 767px) {
    height: 170px !important;
  }
}

.type-card {
  width: 12.5%;
  padding: 0 12px;
  @media screen and (max-width: 861px) and (min-width: 431px) {
    width: 16.66%;
    padding: 0 8px;
  }
  @media screen and (max-width: 430px) {
    width: 33.33%;
    padding: 0 8px;
  }
}

.type-card-img {
  width: 38px;
  height: 38px;
  @media screen and (max-width: 861px) {
    width: 30px;
    height: 30px;
  }

}

.type-card-insert {
  border: 1px solid #ffffff;
  background-color: white;
  color: #3E4957;
  padding: 32px 0;
  @media screen and (max-width: 861px) {
    padding: 24px 0;
  }
  @media screen and (max-width: 430px) {
    padding: 16px 0;
  }
}

.type-card-link:hover .type-card-insert {
  background-color: #FDF2FF;
  border-color: #9B27AF;
}

.type-card-title {
  font-size: 18px;
  @media screen and (max-width: 861px) {
    font-size: 14px;
  }
}

.specifiedTypes {
  height: 520px;
  @media screen and (max-width: 861px) {
    height: 530px;
  }
  @media screen and (max-width: 430px) {
    height: 700px;
  }
  overflow: hidden;
}

.features-block {
  height: 100%;

  @media screen and (max-width: 432px) and (min-width: 400px) {
    height:610px;
    overflow: hidden;
  }
  @media screen and (max-width: 399px) {
    height: 600px;
    overflow: hidden;
  }

}

.rotated {
  transform: rotate(180deg);
}

.specified-types-description {
  font-size: 18px;
  @media screen and (max-width: 861px) {
    font-size: 16px;
  }
  @media screen and (max-width: 430px) {
    font-size: 14px;
  }
}

#showMoreTypes:focus, #showMoreFeatures:focus {
  box-shadow: none !important;
}

.header-item,
.header-item-new {
  color: #3e4957;
}

.header-item:hover {
  background: #eddcf0;
  color: #9b27af;
}

.header-item-new {
  border: 1px solid transparent;
}

.header-item-new:hover {
  color: #9b27af;
  background: #fdf2ff;
  border: 1px solid #9B27AF;
}

.header-item:hover .header-outline {
  display: inline-block;
}

.header-outline {
  display: none;
  height: 4px;
}

.header-item:hover .header-img,
.header-item-new:hover .header-img {
  -webkit-filter: invert(20%) sepia(100%) saturate(2536%) hue-rotate(280deg) brightness(77%) contrast(66%);
  filter: invert(20%) sepia(100%) saturate(2536%) hue-rotate(280deg) brightness(77%) contrast(66%);
}

.header-item-new:hover .header-img-arrow {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.header-arrow {
  width: 26px;
  height: 26px;
  background: #fdf2ff;
}

.header-item-new:hover .header-arrow {
  background: #9b27af;
}
.header-img-mobile{
  width: 22px;
  height: 22px;
  @media screen and (max-width: 431px) {
    width: 31px;
    height: 31px;
  }
}
.header-bg {
  background-image: url("/public/static/pages/main-page-img/hero-main-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 99px 0 118px 0;
  @media screen and (max-width: 1100px) and (min-width: 560px) {
    padding: 48px 0;
    background-image: url("/public/static/pages/main-page-img/hero-main-bg_t.webp");
  }
  @media screen and (max-width: 450px) {
    padding: 48px 0;
    background-image: url("/public/static/pages/main-page-img/hero-main-bg_m.webp");
  }

}

.header-text {
  font-size: 20px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.unlimited-creation {
  background-image: url("/public/static/pages/main-page-img/unlimited-creation.svg");
  background-color: white;
  background-repeat: no-repeat;
  background-position: 90%;
  height: 204px;
  display:block;


  @media screen and (max-width: 1200px) and (min-width: 767px) {
    height: 197px;
  }
  @media screen and (max-width: 431px) {
    height:204px;
  }
  @media screen and (max-width: 1441px) {
    background-position: right;
  }
  &:hover {
    background-color: #FDF2FF;
    outline: 1px solid #9B27AF;
  }
}

.code-application {
  background-image: url("/public/static/pages/main-page-img/code-application.svg");
  background-color: white;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  height: 116px;
  display: block;


  @media screen and (max-width: 1200px) and (min-width: 767px) {
    height: 115px;
  }
  @media screen and (max-width: 431px) {
    height:116px;
  }
  @media screen and (max-width: 860px) {
    background-image: url("/public/static/pages/main-page-img/code-application_m.png");
    background-position: right;
  }
  &:hover {
    background-color: #FDF2FF;
    outline: 1px solid #9B27AF;
  }
}

.different-types {
  background-image: url("/public/static/pages/main-page-img/different-types.svg");
  background-color: white;
  background-repeat: no-repeat;
  background-position: right;
  height: 194px;
  display:flex;
  align-items: center;

  @media screen and (max-width: 1200px) and (min-width: 767px) {
    height: 178px;
  }
  @media screen and (max-width: 431px) {
    height:194px;
  }
  &:hover {
    background-color: #FDF2FF;
    outline: 1px solid #9B27AF;
  }
}

.code-design {
  background-image: url("/public/static/pages/main-page-img/design.svg");
  background-color: white;
  background-repeat: no-repeat;
  background-position: bottom center;
  &:hover {
    background-color: #FDF2FF;
    outline: 1px solid #9B27AF;
  }
}
.scanning{
  &:hover {
    background-color: #FDF2FF;
    outline: 1px solid #9B27AF;
  }
}

.access {
  background-image: url("/public/static/pages/main-page-img/access.svg");
  background-color: white;
  background-repeat: no-repeat;
  background-position: right;
  height: 130px;
  display:flex;
  align-items: center;

  @media screen and (max-width: 900px) {
    background-position: 130%;
  }
  &:hover {
    background-color: #FDF2FF;
    outline: 1px solid #9B27AF;
  }
}
.dynamic{
  height: 137px;
  display:flex;
  align-items: center;

  @media screen and (max-width: 1200px) and (min-width: 767px) {
    height: 134px;
  }
  @media screen and (max-width: 431px) {
    height:167px;
  }
  &:hover {
    background-color: #FDF2FF;
    //cursor: default;
    outline: 1px solid #9B27AF;
  }
}
.trackable:hover {
  background-color: #FDF2FF;
  //cursor: default;
  outline: 1px solid #9B27AF;
}
.free-creation{
  height: 443px;
  display:flex;
  align-items: center;


  @media screen and (max-width: 1200px) and (min-width: 767px) {
    height: 418px;
  }
  @media screen and (max-width: 431px) {
    height:400px;
  }
  &:hover {
    background-color: #FDF2FF;
    outline: 1px solid #9B27AF;
  }
}
.samples {
  background-image: url("/public/static/pages/main-page-img/samples.png");
  background-color: white;
  background-repeat: no-repeat;
  background-position: right;
  &:hover {
    background-color: #FDF2FF;
    //cursor: default;
    outline: 1px solid #9B27AF;
  }

}

.analytics {
  background-image: url("/public/static/pages/main-page-img/analytics.svg");
  background-color: white;
  background-repeat: no-repeat;
  background-position: 10%;
  &:hover {
    background-color: #FDF2FF;
    //cursor: default;
    outline: 1px solid #9B27AF;
  }
}

.features {
  background-color: white;

  //&:hover {
  //  background-color: #FDF2FF;
  //  cursor: default;
  //  outline: 1px solid #9B27AF;
  //}
}

//#features ready:hover{
//    background-color: #FDF2FF;
//    cursor: default;
//    outline: 1px solid #9B27AF;
//  }


.solutions-description {
  font-size: 24px;
  @media screen and (max-width: 900px) and (min-width: 500px) {
    font-size: 18px;
  }
  @media screen and (max-width: 431px) {
    font-size: 14px;
  }
}

.solutions-count {
  font-size: 48px;
  @media screen and (max-width: 900px) and (min-width: 500px) {
    font-size: 32px;
  }
  @media screen and (max-width: 431px) {
    font-size: 16px;
  }
}

.solutions-img {
  width: 30px;
  height: 30px;
  @media screen and (max-width: 900px) and (min-width: 500px) {
    width: 24px;
    height: 24px;
  }
  @media screen and (max-width: 431px) {
    width: 20px;
    height: 20px;
  }
}

.carousel-news-card {
  height: 460px;
}

.step-title {
  font-size: 24px;
  @media screen and (max-width: 900px) {
    font-size: 16px;
  }

}

.description-text {
  font-size: 18px;
  @media screen and (max-width: 900px) and (min-width: 500px) {
    font-size: 16px;
  }
  @media screen and (max-width: 431px) {
    font-size: 14px;
  }
}

.features-text {
  //margin-bottom: 0;
  font-size: 16px;
  @media screen and (max-width: 900px) and (min-width: 500px) {
    font-size: 14px;
  }
}

.tablet-features {
  display: none !important;
  @media screen and (max-width: 1100px) and (min-width: 560px) {
    display: flex !important;
  }

}

.popover{
  opacity: 90% !important;
}
.bg-dark-purple .popover-body{
  color: #3C057E!important;
}
.new-pricing-bg{
  background-color: #FDF2FF;
  &.popover-body{
    color: #FFFFFF!important;
    background-color: #3E4957!important;
  }
}

.template-main-img{
  top:25%;
  @media screen and (max-width: 432px) {
    padding: 0 11px;
  }
}

.template-main-container{
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    overflow: auto;
  }
}

.template-icon {
  fill: #3E4957;
  width: 38px;
  height: 38px;
}
.btn-main-templates:hover .template-icon {
  fill: #9B27AF;
}
.btn-main-templates{
  border-color: #FFFFFF!important;
  color: #3E4957!important;
  &:hover{
    color: #9B27AF!important;
    border-color: #9B27AF!important;
    background-color: #FDF2FF!important;
  }
  &.active{
    color: #9B27AF!important;
    border-color: #9B27AF!important;
    background-color: #FDF2FF!important;
     .template-icon {
      fill: #9B27AF;
    }
  }
}



.template-main-item-img{
  height: 383px;
  border-radius: 30px;
  @media screen and (max-width: 1400px) and (min-width: 350px) {
    border-radius: 20px;
    height: 327px;
  }
  border: solid 4px #F5F5F5;
  &:hover{
    border-color:#eddcf0;

  }

.dots {
  background-image: url("/public/static/pages/main-page-img/dots.png");
  background-color: white;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  &:hover {
    background-color: #FDF2FF;
    outline: 1px solid #9B27AF;
  }
}

.file {
  background-image: url("/public/static/pages/main-page-img/file.png");
  background-color: white;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  &:hover {
    background-color: #FDF2FF;
    outline: 1px solid #9B27AF;
  }
}

.shapes {
  background-image: url("/public/static/pages/main-page-img/shapes.png");
  background-color: white;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  &:hover {
    background-color: #FDF2FF;
    outline: 1px solid #9B27AF;
  }
}
}
.lang-bar{
  height: 310px;
  &::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }
  &::-webkit-scrollbar-track{
    background: #F5F5F5;
  }
  &::-webkit-scrollbar-thumb{
    background-color: #DDE0E4;
    border-radius: 20px;
  }
}
.lang-code{
  min-width: 25px;
}
.dropdown-item.lang {
  color: #8b929a;
  &:hover{
    background-color: #F5E7F8!important;
    border-radius: 4px;
  }

}

.pricing-tr{
  height: 40px
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap");
.coupon-description{
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 20px;
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}
.coupon-main{
  background-image: url("/public/static/pages/main-page-img/coupon-pattern.webp");
  @media screen and (max-width: 500px) {
    background-image: url("/public/static/pages/main-page-img/coupon-pattern-mob.webp");
  }
}
.carousel-indicators {
  position: absolute;
  top: 100%;
  left: 0;

  & button {
    border-radius: 16px 16px 16px 16px !important;
    @media screen and (max-width: 432px) {
      margin: 0 4px !important;
      width: 8px !important;
      height: 8px !important;
      border-radius: 8px 8px 8px 8px !important;
    }

    &.active {
      width: 47px !important;
      @media screen and (max-width: 432px) {
        width: 24px !important;
      }
    }
  }
}