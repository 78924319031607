$primary: #9b27af;
$gray: #8b929a;
$light: #f8f9fa;
$title: #3c4858;
$success: #67ac5b;
$secondary: #796fef;
$info: #8b929a;
$dark: #3e4957;
$dark-purple: #3c057e;
$border-gray: #dde0e4;
$font-family-sans-serif: "Arial";
$headings-font-weight: 700;
$red: #eb5647;
$green: #16b054;
$dark-green: #175743;
$yellow: #ffe459;
$google-forms: #7248b9;
$google-doc: #4486f4;
$black: #000000;
$gold: #FED201;
$orange: #f8a40f;


$theme-colors: (
  "primary": $primary,
  "gray": $gray,
  "light": $light,
  "title": $title,
  "success": $success,
  "secondary": $secondary,
  "info": $info,
  "dark": $dark,
  "dark-purple": $dark-purple,
  "red": $red,
  "green": $green,
  "dark-green": $dark-green,
  "yellow": $yellow,
  "gold": $gold,
  "google-doc": $google-doc,
  "google-forms": $google-forms,
  "black": $black,
        "orange": $orange,

);
$accordion-button-focus-border-color: #ffffff;
$accordion-button-focus-box-shadow: #ffffff;
$accordion-button-active-bg: #ffffff;

//fonts
$h1-font-size: 3rem; //(48px)
$h2-font-size: 2.5rem; //(40px)
$h3-font-size: 2rem; //(32px)
$h4-font-size: 1.5rem; //(24px)
$h5-font-size: 1.25rem; //(20px)
$h6-font-size: 1.125rem; //(18px)
$h7-font-size: 0.875rem; //(14px)
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1040px,
        xl: 1319px,
        xxl: 1320px,
);
$grid-breakpoints: (
        xs: 0,
        sm: 431px,
        md: 745px,
        lg: 1025px,
        xl: 1281px,
        xxl: 1441px
);
$carousel-indicator-width: 16px;
$carousel-indicator-height: 16px;
$carousel-indicator-hit-area-height: 0;
$carousel-indicator-spacer: 8px;
$carousel-indicator-opacity: 0.6;
$carousel-indicator-active-bg: #9b27af;
$carousel-control-color: #3e4957;
$carousel-control-width: 5%;
$carousel-control-opacity: 0.4;

@import "../base/module/nav";
//$: 14px;
@import "./home-list.scss";
@import "bootstrap";
@import "../../../public/assets/css/modal-video.min.css";
// Configuration
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
//
//// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
//@import "tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
//@import "button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
//@import "card";
@import "bootstrap/scss/accordion";
//@import "breadcrumb";
//@import "pagination";
//@import "badge";
//@import "alert";
//@import "progress";
//@import "list-group";
//@import "close";
//@import "toasts";
@import "bootstrap/scss/modal";
//@import "tooltip";
//@import "popover";
@import "bootstrap/scss/carousel";
//@import "spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";
